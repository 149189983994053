<template>
  <div class="table-container">
    <ABTable :columns="columns" :tableData="rows" :page-title="pageTitle"  :showCreateButton="false" :routerUrl="'PastDeliveryDetails'" :hasDetailsPage="false" :hasEditPage="false" :noActions="true" :isLoading="isLoading" :page="remoteParams.page" :pageSize="remoteParams.pageSize" :totalCount="totalCount"
      @onFetchRemoteData="fetchRemoteData" class="styled-table" >
      <!-- Table Header -->
      <template #tableHeader="{ columns }">
        <thead>
          <tr>
            <th v-for="column in columns" :key="column.field">{{ column.label }}</th>
          </tr>
        </thead>
      </template>

      <!-- Clickable Table Rows -->
      <template #tableBody="{ rows, columns }">
        <tbody>
          <tr
            v-for="row in rows"
            :key="row.id"
            @click="handleRowClick(row.deliveryNoteIds, row.id)"
            class="clickable-row"
          >
            <td v-for="column in columns" :key="column.field">
              {{ row[column.field] }}
            </td>
          </tr>
        </tbody>
      </template>

      <!-- Loading State -->
      <template #loading>
        <div class="loading-text">Loading...</div>
      </template>

      <!-- No Data Available -->
      <template #noData>
        <div class="no-data-text">No Data Available</div>
      </template>
    </ABTable>
  </div>
</template>


<script>
import ABTable from "@/components/ABTable.vue";
import { useDeliveryNotePrintedStore } from '@/stores/deliveryNotePrinted.js';
import PastDeliveryDetails from "./PastDeliveryDetails.vue";

export default {
  components: {
    ABTable,
  },
  data() {
    return {
      remoteParams: {
        page: 1,
        pageSize: 10,
        sortBy: "Id",
        sortDirection: "desc",
        searchQuery: "", // Keep track of search query
      },
      isLoading: false,
      pageTitle: 'Past Delivery Notes',
      columns: [
        { label: "Id", field: "id", type: "number", width: "5%" },
        { label: "Bag Number", field: "bagNumbers", type: "number", width: "5%" },
      ],
      rows: [],
      totalCount: 0,
    };
  },
  mounted() {
    this.fetchRemoteData({ page: 1, pageSize: this.remoteParams.pageSize });
  },
  methods: {
    async fetchRemoteData(remoteParams) {
      // Ensure `remoteParams` gets updated before making API call
      this.remoteParams = { ...this.remoteParams, ...remoteParams };
      this.isLoading = true;

      const deliveryNoteStore = useDeliveryNotePrintedStore();
      console.log("Fetching with Search Query:", this.remoteParams.searchQuery); // Debugging

      try {
        const response = await deliveryNoteStore.searchDeliveryNotePrinted(
          this.remoteParams.page,
          this.remoteParams.pageSize,
          this.remoteParams.sortBy,
          this.remoteParams.sortDirection,
          this.remoteParams.searchQuery // Pass search query
        );

        console.log("API Response:", response); // Log the full response

        // Ensure response and response.items exist before setting rows
        if (response && response.items) {
          this.rows = response.items;
          this.totalCount = response.totalCount || 0; // Ensure totalCount is valid
        } else {
          console.error("Invalid API response:", response);
          this.rows = [];
          this.totalCount = 0;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        this.rows = [];
        this.totalCount = 0;
      }

      this.isLoading = false;
    },

    handleRowClick(deliveryNoteIds, id) {
      console.log("Row clicked, navigating to:", id);
      localStorage.setItem("selectedRowId", id);
      
      // Convert deliveryNoteIds array to a string for routing
      const result = JSON.stringify(deliveryNoteIds).replace(/[\[\]]/g, '');
      
      // Ensure router is available before navigating
      if (this.$router) {
        this.$router.push(`/PastDeliveryDetails/${result}`);
      } else {
        console.error("Vue Router not found!");
      }
    }
  },

};
</script>


<style scoped>
/* Center the table */
.table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

/* Style the table */
.styled-table {
  width: 80%;
  max-width: 1000px;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}

/* Header Styling */
.styled-table th {
  background-color: #008cba;
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 12px;
}

/* Row Styling */
.styled-table td {
  text-align: center;
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

/* Clickable Row */
.clickable-row {
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.clickable-row:hover {
  background-color: #f1f1f1;
}

/* Search Input */
.search-input {
  padding: 8px;
  width: 250px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

/* Loading and No Data */
.loading-text, .no-data-text {
  text-align: center;
  padding: 20px;
  font-size: 16px;
  color: #555;
}
</style>
