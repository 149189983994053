<template>
  <div class="container-fluid row">
    <div class="col-md-12">
      <h2 class="text-center my-3">Aging Bags Report</h2>

      <!-- Input Field for Bag Numbers -->
      <div class="input-section">
        <label for="bagNumbers" class="form-label">Enter Bag Numbers (comma-separated):</label>
        <textarea id="bagNumbers"  v-model="bagNumbers" placeholder="E.g., H1193059, G1193014, F1192957" class="form-control"></textarea>
        <button @click="fetchAgingBagsReport" class="btn btn-primary my-3">Generate Report</button>
      </div>

      <!-- Display Error Message -->
      <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>

      <!-- Table to Display the Report -->
      <div v-if="isLoading" class="text-center my-3">
        <span>Loading...</span>
      </div>
      <div v-if="reportData.length > 0">
        <h3>Report Data</h3>
        <table class="table table-bordered table-striped">
          <thead class="table-dark">
            <tr>
              <th>Bag Number</th>
              <th>Delivery Note ID</th>
              <th>Assigned To</th>
              <th>Bx2 Assigned To</th>
              <th>Created Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in reportData" :key="index">
              <td>{{ row.bagNumber }}</td>
              <td>{{ row.deliveryNoteId }}</td>
              <td>{{ row.assignedTo || "N/A" }}</td>
              <td>{{ row.bx2AssignedTo || "N/A" }}</td>
              <td>{{ formatDate(row.createdDate) }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Show an error message if no data is available -->
      <p v-else class="text-center my-3 text-danger">No records found.</p>

      <!-- Export Button -->
      <button v-if="reportData.length > 0" id="export" class="upload-btn my-3 mx-1" @click="exportToCSV">
        Export CSV
      </button>
    </div>
  </div>
</template>

<script>
import { useAgedBagsStore } from "@/stores/agedBags.js";

export default {
  data() {
    return {
      bagNumbers: "",
      reportData: [], // Holds API response
      isLoading: false,
      errorMessage: "",
    };
  },

  methods: {
    async fetchAgingBagsReport() {
      this.errorMessage = "";
      this.reportData = []; // Reset previous results

      const formattedBagNumbers = this.bagNumbers
        .split(",")
        .map((bag) => bag.trim())
        .filter((bag) => bag !== "");

      if (formattedBagNumbers.length === 0) {
        this.errorMessage = "Please enter at least one bag number.";
        return;
      }

      this.isLoading = true;

      try {
        console.log("🔍 Requesting data for:", formattedBagNumbers);
        const AgedBagsStore = useAgedBagsStore();
        let response = await AgedBagsStore.searchAgedBags(formattedBagNumbers);

        //Fix: Convert response to JSON before using it
        if (response.ok) {
          response = await response.json(); // Convert Response to JSON
        } else {
          throw new Error("Failed to fetch data.");
        }

        //Ensure Vue updates the table with the parsed data
        if (Array.isArray(response) && response.length > 0) {
          this.reportData = response;
        } else {
          this.errorMessage = "No records found.";
        }
      } catch (error) {
        this.errorMessage = "Error fetching data. Please try again.";
      } finally {
        this.isLoading = false;
      }
    },

    formatDate(dateString) {
      return dateString ? new Date(dateString).toLocaleString() : "N/A";
    },

    exportToCSV() {
      const csvHeader = "Bag Number,Delivery Note ID,Assigned To,Bx2 Assigned To,Created Date";
      const csvData = this.reportData.map(row =>
        `${row.bagNumber},${row.deliveryNoteId},${row.assignedTo || "N/A"},${row.bx2AssignedTo || "N/A"},${this.formatDate(row.createdDate)}`
      );
      const csvContent = [csvHeader, ...csvData].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "AgingBagsReport.csv";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style scoped>
.container-fluid {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.input-section {
  margin-bottom: 20px;
}

.upload-btn {
  float: right;
  background-color: #4caf50;
  color: white;
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.upload-btn:hover {
  background-color: #388e3c;
}

button.btn-primary {
  display: block;
  margin: auto;
  padding: 10px 15px;
  font-size: 1em;
}

textarea.form-control {
  width: 100%;
  height: 80px;
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
}

.text-danger {
  margin-top: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
}

.table-dark {
  background-color: #008cba;
  color: white;
}
</style>
