import { createRouter, createWebHistory } from 'vue-router'

import Cookies from 'js-cookie'

import { storeUtils } from '../utils/store.js'

import { useAuthStore } from '@/stores/auth'


//Layouts
import AuthLayout from '../layouts/AuthLayout.vue'
import DefaultLayout from '../layouts/DefaultLayout.vue'

//Auth Views
import AuthLogin from '../views/Auth/Login.vue'

import NotAllowedException from '../views/Exceptions/403.vue'

//Views
import GoodsTable from '../views/GoodsTable.vue'
import GoodsTableReturned from '../views/GoodsTableReturned.vue'
import UploadGoods from '../views/UploadGoods.vue'
import EmployeeTable from '../views/EmployeeTable.vue'
import deliveryReport from '../views/DeliveryReport.vue'
import toDeliver from '../views/ToDeliver.vue'
import QualifiersTab from '../views/QualifiersTab.vue'
import InStock from '../views/InStock.vue'
import PastDelivery from '../views/PastDelivery.vue'
import PastReport from '../views/PastReport.vue'
import EmployeeHours from '../views/EmployeeHours.vue'
import PastDeliveryDetails from '../views/PastDeliveryDetails.vue'
import auditLogs from '../views/Audit/Index.vue'
import InHouseBagsReport from '../views/InHouseBagsReport.vue'
import AgingBags from '@/views/AgingBags.vue'


const authRoutes = [
  {
    path: '/login',
    name: 'login',
    meta: { layout: AuthLayout, title: 'Login' },
    component: AuthLogin
  }
]
const routes = [
  {
    path: '/',
    name: 'goodsTable',
    meta: { layout: DefaultLayout , title: 'Issued' },
    component: GoodsTable
  },
  {
    path: '/403',
    name: '403Page',
    meta: { layout: DefaultLayout , title: '403' },
    component: NotAllowedException
  },
  {
    path: '/returned',
    name: 'goodsTableReturned',
    meta: { layout: DefaultLayout  , title: 'Return'},
    component: GoodsTableReturned
  },
  {
    path: '/upload',
    name: 'uploadGoods',
    meta: { layout: DefaultLayout , title: 'Upload Goods'},
    component: UploadGoods
  },
  {
    path: '/employee',
    name: 'employeeTable',
    meta: { layout: DefaultLayout , title: 'Employee' },
    component: EmployeeTable
  },
  {
    path: '/DeliveryNote/Valid',
    name: 'deliveryReport',
    meta: { layout: DefaultLayout , title: 'Delivery Report' },
    component: deliveryReport
  },
  {
    path: '/toDeliver/:ids',
    name: 'toDeliver',
    meta: { layout: DefaultLayout , title: 'To Delivery' },
    component: toDeliver
  },
  {
    path: '/QualifiersTab',
    name: 'QualifiersTab',
    meta: { layout: DefaultLayout , title: 'Qualifiers', adminOnly:true },
    component: QualifiersTab
  },
  {
    path: '/InHouseBagsReport',
    name: 'InHouseBagsReport',
    meta: { layout: DefaultLayout , title: 'InHouse Bags' },
    component: InHouseBagsReport
  },
  {
    path: '/InStock',
    name: 'InStock',
    meta: { layout: DefaultLayout , title: 'In Stock' },
    component: InStock
  },
  {
    path: '/PastDelivery',
    name: 'PastDelivery ',
    meta: { layout: DefaultLayout , title: 'Past Delivery' },
    component: PastDelivery
  },
  {
    path: '/PastDeliveryDetails/:ids',
    name: 'PastDeliveryDetails ',
    meta: { layout: DefaultLayout , title: 'Past Delivery Details' },
    component: PastDeliveryDetails
  },
  {
    path: '/PastReport',
    name: 'PastReport',
    meta: { layout: DefaultLayout , title: 'Past Report' },
    component: PastReport
  },
  {
    path: '/agingBags',
    name: 'AgingBags',
    meta: { layout: DefaultLayout , title: 'Aging Bags' },
    component: AgingBags
  }
  , {
    path: '/EmployeeHours',
    name: 'EmployeeHours',
    meta: { layout: DefaultLayout , title: 'Employee Hours', adminOnly:true},
    component: EmployeeHours
  }
  , {
    path: '/Audit',
    name: 'Audit',
    meta: { layout: DefaultLayout },
    component: auditLogs
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...routes, ...authRoutes]
})

const nonAuthRoutes = ['login']
router.beforeEach(async(to, from, next) => {
  const title = to.meta.title
  const adminOnly = to?.meta?.adminOnly || false
  console.log(adminOnly)
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    document.title = title
  }
  const hasToken = storeUtils.getToken()
  if (hasToken) {

    const authStore = useAuthStore()
    const response = await authStore.getMyUser()
    if(response.status == 401){
      Cookies.remove('authToken')
      next({ name: 'login' })
    }
    var json = await response.json()
    Cookies.set("loggedInUser", JSON.stringify(json))
    if(nonAuthRoutes.includes(to.name)){
      next({ name: 'goodsTable' })
    } else if(adminOnly && storeUtils.getLoggedInUser().role != 'admin'){
      next({ name: '403Page' })
    }
  } else {
    if (nonAuthRoutes.includes(to.name)) {
      next()
    } else{
      next({ name: 'login' })
    }
  }
  next()
})

export default router
