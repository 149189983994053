<template>
  <nav class="navbar fixed-top">
    <div class="container">
      <!-- Upload Button -->
      <router-link to="/upload" class="upload-btn">Upload</router-link>

      <!-- Navbar Links -->
      <ul class="navbar-menu" :class="{ 'show': isNavbarOpen }">
        <li><router-link to="/employee" class="navbar-link">Employee</router-link></li>
        <li><router-link to="/" class="navbar-link">Issued</router-link></li>
        <li><router-link to="/returned" class="navbar-link">Returned</router-link></li>
        <li><router-link to="/DeliveryNote/Valid" class="navbar-link">Received</router-link></li>
        <li><router-link to="/QualifiersTab" class="navbar-link">Qualifiers</router-link></li>
        <li><router-link to="/InStock" class="navbar-link">Stock</router-link></li>
        <li><router-link to="/InHouseBagsReport" class="navbar-link">InHouse Bags</router-link></li>
        <li><router-link to="/PastDelivery" class="navbar-link">Past Delivery Notes</router-link></li>
        <li><router-link to="/AgingBags" class="navbar-link">Aging Bags Report</router-link></li>
        <li><router-link to="/EmployeeHours" class="navbar-link">Salary</router-link></li>
      </ul>

      <!-- User Dropdown Menu -->
      <div class="user-dropdown">
        <button class="user-btn" @click="toggleUserMenu">
          {{ storeUtils.getLoggedInUser().username }} ▼
        </button>
        <div class="dropdown-menu" v-if="isUserMenuOpen">
          <button @click="logOut()" class="logout-btn">Log out</button>
        </div>
      </div>

      <!-- Burger Menu (Only on Small Screens) -->
      <button class="navbar-toggler" @click="toggleNavbar">☰</button>
    </div>
  </nav>

  <div class="page-content">
    <slot />
  </div>
</template>

<script>
import { storeUtils } from '../utils/store.js'
import Cookie from 'js-cookie'

export default {
  data() {
    return {
      storeUtils,
      isNavbarOpen: false,
      isUserMenuOpen: false // Controls dropdown visibility
    }
  },
  methods: {
    logOut() {
      Cookie.remove("authToken");
      Cookie.remove("loggedInUser");
      this.$router.replace("/login");
    },
    toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen;
    },
    toggleUserMenu() {
      this.isUserMenuOpen = !this.isUserMenuOpen;
    }
  }
}
</script>

<style scoped>
@import "@/assets/css/style.css";

/* Navbar Styles */
.navbar {
  background-color: #008cba;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8em 1.5em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  flex-wrap: nowrap;
}

/* Hide Burger Menu on Desktop */
.navbar-toggler {
  display: none;
  background: none;
  border: none;
  font-size: 1.5em;
  color: #fff;
  cursor: pointer;
}

/* Navbar Menu */
.navbar-menu {
  list-style: none;
  display: flex;
  /*flex: 1 1 auto;  ✅ Allows shrinking */
  justify-content: center;
  margin: 0;
  padding: 0;
  gap: 5px; /* ✅ Reduces spacing */
}

.navbar-menu li {
  white-space: nowrap;
  font-size: 1.2em; 
}

.navbar-link {
  text-decoration: none;
  font-size: 0.85em;
  color: #fff;
  padding: 0.4em 0.6em;
  transition: 0.3s;
}

.navbar-link:hover {
  color: #b2dfdb;
}

/* Upload Button */
.upload-btn {
  background-color: #4CAF50;
  color: white;
  padding: 0.4em 0.8em;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.3s;
}

.upload-btn:hover {
  background-color: #43A047;
}

/* User Dropdown */
.user-dropdown {
  position: relative;
  display: flex;
  align-items: center;
}

.user-btn {
  background-color: red;
  border: none;
  color: white;
  font-size: 1em;
  cursor: pointer;
  padding: 0.5em 1em;
  border-radius: 5px;
  transition: 0.3s;
}

.user-btn:hover {
  background-color: darkred;
}

/* Dropdown Menu */
.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  display: flex;
  flex-direction: column;
  min-width: 150px;
  z-index: 1000;
}

.logout-btn {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.3s;
  width: 100%;
}

.logout-btn:hover {
  background-color: #e53935;
}

/* Mobile View */
@media (max-width: 1218px) {
  .navbar-toggler {
    display: block; /* ✅ Show burger menu only on small screens */
  }

  .navbar-menu {
    display: none;
  }

  .navbar-menu.show {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #008cba;
    width: 100%;
    padding: 1em;
    z-index: 1000;
  }

  .navbar-menu li {
    padding: 10px 0;
  }
}

/* Page Content */
.page-content {
  padding-top: 4.5em;
}
</style>
