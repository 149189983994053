import { defineStore } from 'pinia'
import { storeUtils } from '../utils/store.js'

export const useAgedBagsStore = defineStore({
  id: 'AgedBags',
  state: () => ({
    // discounts: [], //avoid using global state if at all possible
  }),
  actions: {

    async searchAgedBags(bagNumbers) {
      const queryParams = new URLSearchParams({
        bagNumbers: bagNumbers.join(','), // Convert array to comma-separated string
        page: 1,
        pageSize: 10,
        sortBy: "Id",
        sortDirection: "asc"
      });
    
      const endpoint = `AgedBags/agedbagsreport?${queryParams.toString()}`;
      return await storeUtils.fetch(endpoint);
    }
    


  //   async searchAgedBags(bagNumbers, page = 1, pageSize = 10, sortBy = "", sortDirection = "") {
  //     if (!Array.isArray(bagNumbers) || bagNumbers.length === 0) {
  //         console.error("Invalid bag numbers:", bagNumbers);
  //         throw new Error("Bag numbers must be an array with at least one value.");
  //     }
  
  //     const bagNumbersParam = encodeURIComponent(bagNumbers.join(","));  // ✅ Correct encoding
  
  //     const endpoint = `AgedBags/aged-bags-report?bagNumbers=${bagNumbersParam}&page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDirection=${sortDirection}`;
  
  //     console.log("Requesting API:", endpoint);  // ✅ Debugging
  
  //     try {
  //         const response = await storeUtils.fetch(endpoint);
  //         console.log("API Response:", response);  // ✅ Debugging
  //         return response;
  //     } catch (error) {
  //         console.error("API Request Failed:", error);
  //         throw error;
  //     }
  // }
  }  
})